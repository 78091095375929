<template>
  <el-dialog
    v-if="plan"
    :visible.sync="scoreAlert"
    :width="isPhone ? '95%' : '60%'"
    class="scoreAlert"
  >
    <h4 slot="title" class="text-center text-success">
      {{ $t("toefl.balance.Unlock Now") }}
      <hr />
    </h4>
    <div class="mb-4">
      <el-form label-width="150px" label-position="top">
        <el-form-item label="Test Name">
          <b class="text-success">
            {{ getCompanyExamTitle(exam.title) }}
          </b>
        </el-form-item>
        <el-form-item label="Questions">
          <b class="text-success">{{ sectionCount }}</b>
          Section(s)
          <b class="ml-3 text-success">
            {{ questionCount }}
          </b>
          Questions
        </el-form-item>
        <el-form-item
          label="Time"
          v-if="
            practiceType === 'composePractices' ||
              practiceType === 'Partial Tests'
          "
        >
          <el-radio-group v-model="timeType">
            <el-radio-button :label="1">
              Normal Time
            </el-radio-button>
            <el-radio-button :label="1.5">
              Time and One-Half (+50%)
            </el-radio-button>
            <el-radio-button :label="2">
              Double Time (+100%)
            </el-radio-button>
            <el-radio-button :label="0">
              Not Timed (∞)
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Rating">
          <el-rate v-model="Rating" text-color="#ff9900" disabled></el-rate>
        </el-form-item>
        <el-form-item label="Coin(s) Required">
          <b class="text-success">
            {{ plan.coin }}
            {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
          </b>
        </el-form-item>
      </el-form>
      <div v-if="paymentMethod == 1">
        <hr />
        <h2 class="mb-3 text-right text-success">
          ${{ plan.coin.toFixed(2) }} USD
        </h2>
        <div class="text-right">
          <el-button type="success">
            {{ $t("toefl.Pay now") }}
          </el-button>
        </div>
      </div>
      <div v-if="paymentMethod == 2">
        <template v-if="balanceMethod === 1">
          <hr />
          <el-form label-width="150px" label-position="top">
            <el-form-item>
              <span slot="label">
                Your Balance
              </span>
              <b class="text-success">
                {{ balance }}
                {{ balance > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
              </b>
              <router-link
                :to="{ name: 'UserProfile', query: { active: 'balance' } }"
                class="ml-2"
              >
                <el-button size="mini" type="success">
                  Deposit
                </el-button>
              </router-link>
            </el-form-item>
          </el-form>
          <Balance :point="balance" v-if="false" />
          <h2 class="mb-3 text-right text-success">
            {{ plan.coin }}
            {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
          </h2>
          <div class="text-right">
            <el-button
              type="success"
              :disabled="plan.coin > balance"
              @click="unlockUserExam"
            >
              Pay and Start
            </el-button>
          </div>
        </template>
        <Trades
          v-if="balanceMethod === 2"
          :useCoin="plan.coin"
          :balance="balance"
        ></Trades>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Balance from "@/components/balance/Balance.vue";
import Trades from "@/components/balance/Trades.vue";
import balance from "@/apis/balance.js";

// import TOEFL from "@/apis/toefl";

export default {
  components: { Balance, Trades },

  mixins: [],

  props: ["questionCount", "sectionCount", "practiceType"],
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    defaultTime() {
      return "120:00";
    }
  },
  data() {
    return {
      timeType: 1,
      Rating: 5,
      exam: null,
      balanceMethod: 1,
      paymentMethod: 2,
      scoreAlert: false,
      balance: 8,
      langList: {
        "EN-US": "en",
        "ZH-CN": "cn",
        "ZH-TW": "tw"
      },
      plan: null
    };
  },
  watch: {},
  mounted() {
    this.balance = this.getProfile.coin;
  },
  destroyed() {},
  methods: {
    getAlert(plan, exam) {
      console.log(plan);
      console.log(exam);
      this.plan = plan;
      this.exam = exam;
      this.scoreAlert = true;
    },
    async unlockUserExam() {
      const res = await balance.unlockUserExam(this.plan.id, {
        exam_id: this.exam.id,
        other_status: {
          timeType: this.timeType
        }
      });
      this.scoreAlert = false;
      this.$message({
        message: "Success!",
        type: "success"
      });
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.push({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType:
              this.exam.test_type ===
              "Modules\\Practice\\Entities\\ComposePractice"
                ? "composePractices"
                : "singlePractice",
            id: this.exam.test_id
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
}
::v-deep .el-form-item__content {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.5rem;
}
.scoreAlert >>> .el-dialog .el-dialog__header {
  padding-bottom: 0;
}
.scoreAlert >>> .el-dialog__body {
  padding: 10px 20px 20px 20px;
}
::v-deep .el-dialog__body {
  /* max-height: 600px; */
  overflow: auto;
}
.left-body {
  width: 50%;
  height: 300px;
  padding: 20px;
}
.right-body {
  width: 50%;
  height: 300px;
  padding: 20px;
}
.info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.tip {
  padding: 8px 16px;
  background-color: var(--themeColor) 40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}
.balance {
  padding-top: 40px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 20px 16px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
.b-info .button {
  width: 140px;
  height: 45px;
  float: right;
  background: var(--themeColor);
  border-radius: 4px;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.b-info .dividing-line {
  border-top: 1px solid #e7ecf1;
  margin-top: 16px;
  margin-bottom: 20px;
}
.trade-list-wrapper {
  margin-top: 20px;
}
.trade-wrapper {
  position: relative;
  width: 100%;
  height: 120px;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  font-size: 24px;
  color: #222;
  letter-spacing: 0;
  line-height: 24px;
  float: left;
  cursor: pointer;
}
.tags {
  position: absolute;
  left: -2px;
  top: -10px;
  height: 20px;
  padding: 0 5px;
  line-height: 18px;
  border-radius: 10px 0 10px 0;
}

.trade-wrapper > div {
  display: table;
  margin: 23px auto 0;
}
.trade-wrapper .bb-name {
  margin-left: 5px;
  font-size: 14px;
}
.trade-wrapper > div > div {
  margin-top: 8px;
  font-size: 14px;
  color: #757575;
  line-height: 14px;
}
.trade-wrapper.selected {
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
  background: #effff6;
  box-shadow: 0 2px 12px 0 #effff6;
}
.trade-customn {
  width: 200px;
  margin-right: 0;
}
.trade-customn .tips {
  margin: 29px 0 0 18px;
  font-size: 14px;
  color: #999;
  letter-spacing: 0;
}
.line {
  color: #999999;
  text-decoration: line-through;
}
</style>
